































































































































































































































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import { validate, phoneNumber, identityNumber, landline, telNumber, is } from '@/utils/helpers'
import { ElForm } from 'element-ui/types/form'
import { DatePickerOptions } from 'element-ui/types/date-picker'
import { information, resident } from '@/utils/options'
import { treeFilter } from 'operation-tree-node'
import { compressImg, imageRotate } from '@/components/common/compressImg'
import _ from 'lodash'
import mitt from '@/utils/mitt'
import HouseDrawer from '@/views/HouseManagement/HouseManagement/HouseList/HouseDrawer.vue'
import RoomDrawer from '@/views/HouseManagement/HouseManagement/HouseDetail/Rooms/RoomDrawer.vue'
import Modal from '@/components/common/Modal'
import { fetchAll } from '@/utils'
import UpdateDateDialog from '@/views/PopulationManagement/ResidentManagement/components/UpdateDateDialog.vue'

const drawer = Modal(HouseDrawer)
const roomDrawer = Modal(RoomDrawer)
type AccessCards = {
  cardNumber: string
}[]
@Component({ components: { FormDrawer, HouseDrawer, RoomDrawer, UpdateDateDialog } })
export default class ResidentDrawer extends Vue {
  @Prop({ default: false }) visible: boolean
  // completeCreate为住户采集面板完善信息创建模式
  @Prop({ default: 'create' }) mode: 'create' | 'update' | 'completeCreate'
  @Prop({ default: () => ({}) }) data: AnyObj

  @Ref() form: ElForm
  @Ref('drawer') drawer?: HouseDrawer
  @Ref('roomDrawer') roomDrawer?: RoomDrawer

  loading = false
  housesLoading = false
  roomsLoading = false

  citizenshipList: { label: string; value: string }[] = []
  houseList: AnyObj[] = []
  landlordHouseList: AnyObj[] = []
  houseProps = { label: 'name', value: 'id' }
  roomList: AnyObj[] = []
  datePickerOptions: DatePickerOptions = {
    disabledDate: time => time.getTime() < Date.now(),
    shortcuts: [
      { text: '3个月', onClick: picker => picker.$emit('pick', this.$moment().add(3, 'month')) },
      { text: '6个月', onClick: picker => picker.$emit('pick', this.$moment().add(6, 'month')) },
      { text: '12个月', onClick: picker => picker.$emit('pick', this.$moment().add(12, 'month')) }
    ]
  }

  formData: AnyObj = {}
  private tipShow = false
  private tipText = ''
  imageData: string | null = null
  imageUrl: string | null = null
  telType = 'phone'
  showDialog = false
  editObj: AnyObj = {}
  accessCards: AccessCards = [{ cardNumber: '' }]
  get isCreationMode() {
    return this.mode === 'create'
  }
  get isCompleteCreationMode() {
    return this.mode === 'completeCreate'
  }

  get isBothCreationMode() {
    return this.mode === 'create' || this.mode === 'completeCreate'
  }

  get isUpdateMode() {
    return this.mode === 'update'
  }

  get title() {
    return this.isUpdateMode ? '编辑住户' : '新建住户'
  }

  get user() {
    return this.$store.state.user || {}
  }

  get isLandlord() {
    return this.formData.type === 'landlord'
  }

  get isRenter() {
    return this.formData.type === 'renter'
  }

  get areaList() {
    const areaList: AnyObj[] = this.$store.state.areas.areas
    return treeFilter(areaList, node => node.statistics.houses > 0)
  }

  get identityTypes() {
    return resident.identityTypes
  }

  get nationalities() {
    return information.nationalities
  }

  get politicals() {
    return information.politicals
  }

  get identity() {
    for (const { value } of resident.identityTypes) {
      if (this.formData[value]) {
        return { identityType: value, identityValue: this.formData[value] }
      }
    }

    return { identityType: 'identityNumber', identityValue: '' }
  }

  get rules() {
    return {
      name: [{ required: true, message: '请输入住户姓名', trigger: 'blur' }],
      type: [{ required: true, message: '请选择住户类型', trigger: 'blur' }],
      identityValue: [
        { required: true, message: '请输入证件号', trigger: 'blur' },
        {
          validator: (rule: any, value: string, callback: Function) => {
            const validator = (validate as Record<string, (v: string) => boolean>)[
              this.formData.identityType
            ]

            if (validator && !validator(value)) {
              callback(new Error('请输入正确的证件号'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      areaCode: [
        { required: true, message: '请输入区号', trigger: 'blur' },
        {
          validator: (rule: any, value: string, callback: Function) => {
            if (this.telType === 'landline' && !/^0\d{2,3}$/.test(value))
              callback(new Error('请输入正确的区号'))
            else callback()
          },
          trigger: 'blur'
        }
      ],
      tel: [
        {
          validator: (rule: any, value: string, callback: Function) => {
            if (!value) return callback()

            const type = this.telType
            if (type === 'phone' && !validate.phoneNumber(value))
              callback(new Error('请输入正确的手机号码'))
            if (type === 'landline' && !validate.simplifiedLandlineNumber(value))
              callback(new Error('请输入正确的座机号码'))
            else callback()
          },
          trigger: 'blur'
        }
      ],
      areaId: [{ required: true, message: '请选择居住地区', trigger: 'blur' }],
      roomId: [{ required: true, message: '请选择居住房屋房间', trigger: 'blur' }],
      houseIds: [
        { required: true, message: '请选择名下房屋', trigger: 'blur' },
        {
          validator: (rule: any, value: string[], callback: Function) => {
            if (value.length === 0) callback(new Error('请选择名下房屋'))
            else callback()
          },
          trigger: 'blur'
        }
      ],
      expireAt: [{ required: true, message: '请选择有效期', trigger: 'blur' }]
    }
  }

  get fields() {
    return [
      'imageUrl',
      'imageData',
      'type',
      'roomId',
      'houseIds',
      'expireAt',
      'profession',
      ...resident.identityTypes.map(({ value }) => value),
      ...this.updatableFields
    ]
  }

  get updatableFields() {
    return [
      'name',
      'citizenship',
      'cardNumber',
      'nationality',
      'tel',
      'political',
      'description',
      'plateNumber',
      'gender'
    ]
  }

  get extraFields() {
    return ['identityType', 'identityValue', 'areaId', 'houseId', 'areaCode']
  }

  get cardNumberParam() {
    return _.map(this.accessCards, item => item.cardNumber)
      .filter(item => item.length)
      .join(';')
  }

  get params() {
    const { areaCode, tel, identityType, identityValue, expireAt } = this.formData

    const params: AnyObj = {
      ...this.formData,
      cardNumber: this.cardNumberParam,
      [identityType]: identityValue,
      imageUrl: this.formData.imageUrl || this.imageUrl,
      imageData: this.imageData
    }

    if (validate.identityNumber(identityValue)) {
      params.gender = identityNumber.getGender(identityValue)
    }

    if (expireAt) {
      params.expireAt = this.$moment(expireAt).endOf('day')
    }

    if (tel) {
      params.tel =
        this.telType === 'phone' ? phoneNumber.standardize(tel) : landline.splicing(areaCode, tel)
    }

    if (this.isUpdateMode) {
      return _.pick(params, this.updatableFields)
    }

    return _.pick(params, this.fields)
  }

  mounted() {
    this.initialize()
    drawer.on('created', this.fetchHouse)
  }
  getCardNumberEdit(index: number) {
    return this.editObj[index]
  }
  setCardNumberEdit(index: number) {
    this.editObj[index] = false
  }
  async fetchHouse() {
    await this.$store.dispatch('areas/fetchAreas')
    this.houseList = await this.fetchHouseList()
  }

  private focusInput() {
    this.tipShow = false
    this.tipText = ''
  }

  private checkUrl(url: string | null) {
    const regUrl = /^(ht|f)tps?:\/\/.*/

    if (!url) {
      this.tipShow = true
      this.tipText = '网址为空，请重新输入'
      return false
    } else if (!regUrl.test(url)) {
      this.tipShow = true
      this.tipText = '网址无效，请重新输入'
      return false
    }
    return true
  }

  private loadPic() {
    const flag = this.checkUrl(this.imageUrl)
    if (flag) this.imageData = this.imageUrl
  }

  private uploadHeadImg() {
    const input: any = this.$refs.hiddenInput
    input.click()
  }

  private handleFile(e: any) {
    this.compress(e.target.files ? e.target.files[0] : null)
    this.imageUrl = null
    this.tipShow = false
  }

  private async compress(file: File) {
    if (!file) {
      return
    }
    const result = await compressImg(file)
    result.onload = (e: any) => {
      imageRotate(e.target.result, this.getImage)
    }
    const input: any = this.$refs.hiddenInput
    input.value = ''
    return
  }

  private getImage(data: string) {
    this.imageData = data
  }

  private addAccessCard() {
    this.accessCards.push({
      cardNumber: ''
    })
  }

  async initialize() {
    this.formData = { citizenship: 'CHN', ...this.data }

    const { tel, imageData } = this.formData
    const { type, areaCode, number } = telNumber.analysis(tel)
    this.telType = type
    if (this.isUpdateMode || this.isCompleteCreationMode) this.accessCardInit()

    if (imageData) {
      this.imageData = imageData
    }
    this.setFormData({ ...this.identity, areaCode, tel: number })
    this.fetchCitizenshipList()
    this.houseList = await this.fetchHouseList()
  }
  accessCardInit() {
    // 初始化门禁卡，将字符串类型的门禁卡数据转换成数组
    let { cardNumber } = this.data
    if (cardNumber == null) {
      cardNumber = []
    } else {
      cardNumber = cardNumber.split(';')
    }
    if (cardNumber.length) {
      this.accessCards = this.handleAccessCard(cardNumber)
    }
    cardNumber.forEach((item: any, index: any) => {
      this.$set(this.editObj, index, true)
    })
  }
  handleAccessCard(cardArr: string[]) {
    return _.map(cardArr, (item: string) => ({ cardNumber: item }))
  }

  close() {
    this.form.resetFields()
    this.formData = {}
    this.landlordHouseList = []
    this.houseList = []
    this.roomList = []
    this.editObj = {}
    this.accessCards = [{ cardNumber: '' }]
    this.visible = false
    this.tipShow = false
    this.imageData = null
    this.imageUrl = null
  }

  createHouse() {
    drawer.open({ mode: 'create' })
  }

  createRoom() {
    roomDrawer.open({
      mode: 'create',
      data: {},
      handleSubmit: async (formData: AnyObj) => {
        const { data } = await this.$api.europa.createRooms({
          ..._.pickBy(formData, is.ava),
          houseId: this.formData.houseId
        })

        if (data.code === 0) {
          this.$message({ message: '新建成功', type: 'success' })
          this.handleHouseChange(this.formData.houseId)
          this.formData.roomId = data.data.id
          mitt.emit('houses/rooms:change')
        }
      }
    })
  }

  async createResident() {
    const params = _.omit(this.params, ['imageUrl', 'imageData'])

    const { data } = await this.$api.europa.createPerson(_.pickBy(params, is.ava))

    if (data.code === 0) {
      const { imageUrl, imageData } = this.params

      if (imageUrl || imageData) {
        const params = { imageUrl, imageData: imageData ? imageData.split(',')[1] : null }
        await this.$api.europa.addPersonImage(data.data.personId, _.pickBy(params, is.ava))
      }

      this.$message({ message: '新建成功', type: 'success' })
      this.$emit('created', this.data)
      this.close()

      mitt.emit('residents:change')
    }
  }

  async updateResident() {
    const { data } = await this.$api.europa.putPerson(
      this.data.personId,
      _.mapValues(this.params, v => (is.ava(v) ? v : null))
    )

    if (data.code === 0) {
      this.$emit('updated', this.data)

      this.$message({ message: '编辑成功', type: 'success' })
      this.close()
    }
  }

  async updateExpireAt({ expireAt, leaseId }: { expireAt: Date; leaseId: string }) {
    this.formData.expireAt = expireAt
    this.formData.leaseId = leaseId
    this.$emit('updated')
  }

  async submit() {
    this.form.validate(async valid => {
      if (valid) {
        this.loading = true

        if (this.isBothCreationMode) {
          await this.createResident()
        } else {
          await this.updateResident()
        }

        this.loading = false
      }
    })
  }

  async fetchCitizenshipList() {
    const { data } = await this.$api.static.getCitizenship()

    this.citizenshipList = data
  }

  async fetchHouseList({ name = '', offset = 0, ids = [] as string[] } = {}) {
    const { areaId } = this.formData

    if (areaId) {
      const limit = 100

      const { data } = await this.$api.europa.getHouseLists(
        _.pickBy({ areaIds: [areaId], limit, offset, name, ids, fuzzy: true }, is.ava)
      )

      if (data.code === 0) {
        return data.data
      }
    }

    return []
  }

  async searchHouses(filterText: string) {
    const houseList = await this.fetchHouseList({ name: filterText })

    return houseList
  }

  async loadHouses(options: AnyObj[], filterText: string) {
    const houseList = await this.fetchHouseList({ name: filterText, offset: options.length })

    return houseList
  }

  async fetchRoomList(houseId: string) {
    const rooms = await fetchAll(offset =>
      this.$api.europa.getRooms({ houseIds: [houseId], offset, limit: 100 })
    )

    this.roomList = rooms

    // 选中的房屋只有一个房间时，默认选中这个房间
    this.$nextTick(() => {
      if (this.roomList.length === 1) {
        this.setFormData({ roomId: this.roomList[0].id })
      }
    })
  }

  setFormData(formData: AnyObj) {
    this.formData = { ...this.formData, ...formData }
  }

  handleTypeChange(value: 'landlord' | 'relative' | 'renter') {
    this.form.clearValidate()

    if (value === 'landlord') {
      this.setFormData({ houseId: '', roomId: '' })
    } else {
      this.setFormData({ houseIds: [] })
    }

    if (value !== 'renter') {
      this.setFormData({ expireAt: null })
    }
  }

  handleTelTypeChange(value: 'phone' | 'landline') {
    this.telType = value
    this.setFormData({ tel: '', areaCode: '' })
    this.form.clearValidate(['tel', 'areaCode'])
  }

  async handleAreaChange() {
    this.setFormData({ houseId: '', houseIds: [], roomId: '' })

    this.housesLoading = true
    this.houseList = await this.fetchHouseList()
    this.housesLoading = false

    this.handleHouseChange('')
  }

  async handleHousesChange() {
    const { houseIds } = this.formData

    this.landlordHouseList = await this.fetchHouseList({ ids: houseIds })

    if (!houseIds.includes(this.formData.houseId)) {
      this.setFormData({ houseId: '', roomId: '' })
    }
  }

  async handleHouseChange(houseId: string) {
    this.setFormData({ roomId: '' })

    if (houseId) {
      this.roomsLoading = true
      await this.fetchRoomList(houseId)
      this.roomsLoading = false
    } else {
      this.roomList = []
    }
  }
}
