









































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'

@Component({})
export default class UpdateDateDialog extends Vue {
  @PropSync('visible') syncedVisible: boolean
  @Prop({}) data: AnyObj
  private shortcutValue = -1
  shortcuts = [
    { label: '续期3个月', value: 3 },
    { label: '续期6个月', value: 6 },
    { label: '续期12个月', value: 12 }
  ]
  loading = false
  expireAt: any = ''

  private close() {
    this.loading = false
    this.expireAt = ''
    this.shortcutValue = -1
  }
  private handleDateRadioChange(value: number) {
    this.expireAt = this.$moment(this.data.expireAt).add(value, 'month')
  }
  private handleDateChange(value: Date) {
    if (value) {
      const changeDate = this.$moment(value).endOf('day')
      const expireAt = this.$moment(this.data.expireAt).endOf('day')
      this.shortcutValue = this.$moment(changeDate).diff(expireAt, 'month', true)
    } else {
      this.shortcutValue = -1
    }
  }
  private async submit() {
    if (this.expireAt) {
      this.loading = true
      const expireAt = this.$moment(this.expireAt).endOf('day')
      const { leaseId } = this.data
      const { data } = await this.$api.europa.updateLease(leaseId, { expireAt })
      this.loading = false
      if (data.code === 0) {
        this.syncedVisible = false
        this.$emit('change', { expireAt, leaseId: data.data.id })
      }
    } else {
      this.$message({ message: '请选择有效期', type: 'error' })
    }
  }

  private get pickerOptions() {
    const options = {
      disabledDate: (d: Date) =>
        this.$moment().add(1, 'day').isAfter(d, 'day') ||
        this.$moment(this.data.expireAt).add(1, 'year').isBefore(d, 'day')
    }
    return { ...options }
  }
}
