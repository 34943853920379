
















































































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import _ from 'lodash'
import { ElForm } from 'element-ui/types/form'
import { getTreeNodePath, is } from '@/utils/helpers'
import treeFind from '@/libs/operation-tree-node/treeFind'
import { options, area } from '@/utils/options'

type Area = { id: string; name: string; level: number; addressType: number; children?: Area[] }

const fromArray = (length: number) => {
  return Array.from({ length }).map((_, index) => index + 1 + '')
}

@Component({ components: { FormDrawer } })
export default class HouseAdder extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: 'create' }) mode: 'create' | 'update'
  @Prop({ default: () => ({}) }) data: AnyObj

  @Ref() form: ElForm

  formData: AnyObj = this.getDefaultFormData()
  loading = false

  get rules() {
    return {
      standardAddress: [
        {
          required: true,
          validator: (_r: any, value: AnyObj, callback: Function) => {
            const { building, unit, floor, houseNumber } = value

            if (this.addressType.value === 1 && !building && !unit && !floor) {
              callback(new Error('请输入楼栋单元'))
            } else if (this.addressType.value === 2 && !houseNumber) {
              callback(new Error('请输入门牌号'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      areaId: [{ required: true, message: '请选择房屋地址', trigger: 'blur' }],
      roomQuantity: [{ required: true, message: '请输入房间数量', trigger: 'blur' }],
      roomCapacity: [{ required: true, message: '请输入房间人口容量', trigger: 'blur' }]
    }
  }

  get title() {
    return this.mode === 'create' ? '新建房屋' : '编辑房屋'
  }

  get isCreateMode() {
    return this.mode === 'create'
  }

  get areaList(): Area[] {
    return this.$store.state.areas.areas
  }

  get addressType() {
    const path = getTreeNodePath(this.areaList[0], this.formData.areaId)
    const oneLevelArea = path.find(({ level }) => level === 1)

    if (oneLevelArea) {
      const addressType = options(area.addressTypes).find(oneLevelArea.addressType)

      if (addressType) {
        return addressType
      }
    }

    return { value: 0, label: '无' }
  }

  get buildingOptions() {
    return fromArray(100).map(n => ({ value: n.toString(), label: n.toString() }))
  }

  get unitOptions() {
    return [
      { value: '', label: '空' },
      ...fromArray(50).map(n => ({ value: n.toString(), label: n.toString() }))
    ]
  }

  get floorOptions() {
    return [
      { value: '', label: '空' },
      { value: 'B2', label: 'B2' },
      { value: 'B1', label: 'B1' },
      ...fromArray(100).map(n => ({ value: n.toString(), label: n.toString() }))
    ]
  }

  mounted() {
    this.initialize()
  }

  getDefaultFormData() {
    return { areaId: '', description: '', coordinates: '', standardAddress: {} }
  }

  async initialize() {
    const { areaId, standardAddress, description } = this.data

    const area = treeFind(this.areaList, ({ id }) => id === areaId)
    if (area && !area.children) {
      this.formData.areaId = areaId
    }

    if (this.isCreateMode) {
      this.formData = { ...this.formData, standardAddress: {}, roomQuantity: 1, roomCapacity: 2 }
    } else {
      this.formData = { ...this.formData, standardAddress, description }
    }
  }

  handleAreaChange() {
    this.form.clearValidate()
  }

  async createHouse() {
    const { data } = await this.$api.europa.createHouse(_.pickBy(this.formData, is.ava))

    return data
  }

  async updateHouse() {
    const { data } = await this.$api.europa.updateHouse(
      this.data.id,
      _.pickBy(this.formData, is.ava)
    )

    return data
  }

  private submit() {
    this.form.validate(async valid => {
      if (valid) {
        this.loading = true

        const data = await (this.mode === 'create' ? this.createHouse() : this.updateHouse())

        this.loading = false

        if (data.code === 0) {
          if (this.isCreateMode || this.formData.areaId !== this.data.areaId) {
            this.$store.dispatch('areas/fetchAreas')
          }

          this.$message({
            message: `${this.mode === 'create' ? '新建' : '编辑'}房屋成功`,
            type: 'success'
          })
          this.$emit(this.mode === 'create' ? 'created' : 'updated')
          this.close()
        }
      }
    })
  }

  private close() {
    this.form.clearValidate()
    this.formData = this.getDefaultFormData()
    this.visible = false
  }
}
